import { Select, SelectProps } from 'antd';
import { orgUnitAPI } from 'api/org-unit';
import { OrgUnit } from 'interfaces/org-unit';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/query-key.enum';
interface Props extends SelectProps<number, OrgUnit> {
  typeId?: number;
  organizationUnitId?: number;
}

const SelectOrgUnit = ({ typeId, organizationUnitId, ...props }: Props) => {
  const [filters, setFilters] = useState({
    keyword: '',
  });

  const onSearch = debounce(
    (value: string) => setFilters((prev) => ({ ...prev, keyword: value })),
    1000
  );

  const { isLoading, data } = useQuery(
    [QUERY_KEY.GET_ORG_UNIT_LIST, typeId, filters],
    () => orgUnitAPI.findAllCompact({ ...filters, typeId })
  );

  return (
    <Select
      loading={isLoading}
      {...props}
      options={
        data?.rows.filter((row) => {
          if (organizationUnitId) {
            return row.id === organizationUnitId;
          }

          return row;
        }) ?? []
      }
      fieldNames={{ label: 'name', value: 'id' }}
      showSearch
      onSearch={onSearch}
      filterOption={false}
    />
  );
};

export default SelectOrgUnit;
