import { Image } from 'antd';
import theme from 'config/theme';
import { memo } from 'react';

import LogoSvg from 'assets/jpg/logo_horizontal.png';

const Logo = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 12,
        marginBottom: 12,
      }}>
      <div
        style={{
          padding: 4,
          backgroundColor: theme.token?.colorBgContainer ?? '#ffffff',
          //borderRadius: '100%',
          //width: 40,
          //height: 500,
        }}>
        <Image src={LogoSvg} alt="logo" preview={false} />
      </div>
    </div>
  );
};

export default memo(Logo);
