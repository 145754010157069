import { PagingData } from 'interfaces/common';
import { OrgUnit } from 'interfaces/org-unit';
import {
  CreateOrgUnitPayload,
  DeleteOrgUnitPayload,
  FindAllOrgUnitPayload,
  FindOneOrgUnitPayload,
  UpdateOrgUnitPayload,
} from 'types/org-unit';
import axiosClient from 'utils/axios-client';

export const orgUnitAPI = {
  findAll: async (
    payload: FindAllOrgUnitPayload
  ): Promise<PagingData<OrgUnit>> => {
    return await axiosClient.get('organization-units', { params: payload });
  },
  findAllCompact: async (
    payload: FindAllOrgUnitPayload
  ): Promise<PagingData<OrgUnit>> => {
    return await axiosClient.get('organization-units/compact', {
      params: payload,
    });
  },
  findOne: async (payload: FindOneOrgUnitPayload): Promise<OrgUnit> => {
    return await axiosClient.get(`organization-units/${payload.id}`);
  },
  create: async (payload: CreateOrgUnitPayload): Promise<OrgUnit> => {
    return await axiosClient.post('organization-units', payload.data);
  },
  update: async (
    payload: UpdateOrgUnitPayload
  ): Promise<PagingData<OrgUnit>> => {
    return await axiosClient.put(
      `organization-units/${payload.id}`,
      payload.data
    );
  },
  delete: async (payload: DeleteOrgUnitPayload): Promise<OrgUnit> => {
    return await axiosClient.delete(`organization-units/${payload.id}`);
  },
};
