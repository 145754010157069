import theme from 'config/theme';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  style?: React.CSSProperties | undefined;
};

const ContentWrapper = ({ children, style }: Props) => {
  return (
    <div
      style={{
        ...style,
        minHeight: '100%',
        padding: 24,
        borderRadius: 8,
        boxShadow: theme.token?.boxShadow,
        backgroundColor: theme.token?.colorBgContainer,
      }}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
