import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from 'antd';
import { filesAPI } from 'api/file';
import { issueAPI } from 'api/issues';
import UploadMultiple from 'components/common/upload-multiple';
import FormInput from 'components/form/form-input';
import PositionInput from 'components/input/position-input';
import SelectDistrict from 'components/select/select-district';
import SelectIssueCategory from 'components/select/select-issue-category';
import SelectIssueLevel from 'components/select/select-issue-level';
import SelectOrgUnit from 'components/select/select-org-unit';
import SelectStation from 'components/select/select-station';
import SelectStreet from 'components/select/select-street';
import { Issue } from 'interfaces/issue';
import { Position } from 'interfaces/position';
import { UploadFile } from 'interfaces/upload-file';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAppSelector } from 'store';
import { selectUserProfile } from 'store/user/user.slice';
import { FormItem } from 'types/form';
import { IssueDataPayload } from 'types/issue';
import { UploadFileResult } from 'types/upload-file';
import { PERMISSION } from 'utils/permission.enum';
import { QUERY_KEY } from 'utils/query-key.enum';

interface Props {
  id: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const formatData = (data: Issue): IssueDataPayload => {
  const {
    districtId,
    categoryId,
    levelId,
    receivedOrganizationUnitId,
    station,
    images,
    videos,
    issuePosition,
    createdPosition,
    address,
    note,
    streetId,
  } = data;
  return {
    districtId,
    categoryId,
    levelId,
    receivedOrganizationUnitId,
    station,
    images,
    videos,
    issuePosition,
    createdPosition,
    address,
    note,
    streetId,
  };
};

const EditIssueModal = ({ id, onCancel, onSubmit }: Props) => {
  const userProfile = useAppSelector(selectUserProfile);
  const { isLoading, data } = useQuery(
    [QUERY_KEY.GET_ISSUE_DETAIL, id],
    () => issueAPI.findOne({ id: id! }),
    {
      enabled: !!id,
      onSuccess: (issue) => {
        setUploadedImage(issue.images?.map((image) => ({ path: image })) ?? []);
        setUploadedVideo(issue.videos?.map((video) => ({ path: video })) ?? []);
      },
    }
  );
  const { mutate, isLoading: updateLoading } = useMutation({
    mutationFn: issueAPI.update,
  });

  const { mutateAsync: uploadFile, isLoading: loadingUpload } = useMutation({
    mutationFn: filesAPI.upload,
  });

  const { mutate: deleteFile, isLoading: loadingDelete } = useMutation({
    mutationFn: filesAPI.delete,
  });

  const queryClient = useQueryClient();
  const [selectedPosition, setSelectedPosition] = useState<Position>();
  const [uploadedImage, setUploadedImage] = useState<UploadFileResult[]>([]);
  const [uploadedVideo, setUploadedVideo] = useState<UploadFileResult[]>([]);
  const items: FormItem[] = [
    {
      label: 'Mức độ',
      formName: 'levelId',
      viewComponent: () => <></>,
      editComponent: () => <SelectIssueLevel />,
    },
    {
      label: 'Loại',
      formName: 'categoryId',
      viewComponent: () => <></>,
      editComponent: () => <SelectIssueCategory />,
    },
    {
      label: 'Quận',
      formName: 'districtId',
      viewComponent: () => <></>,
      editComponent: () => <SelectDistrict />,
    },
    {
      label: 'Đường',
      formName: 'streetId',
      viewComponent: () => <></>,
      editComponent: () => <SelectStreet />,
    },
    {
      label: 'Đơn vị tiếp nhận',
      formName: 'receivedOrganizationUnitId',
      viewComponent: () => <></>,
      editComponent: () => (
        <SelectOrgUnit typeId={data?.issueState.organizationUnitTypeId} />
        // <Typography.Text>
        //   {data?.receivedOrganizationUnit?.name}
        // </Typography.Text>
      ),
    },
    {
      label: 'Người báo cáo',
      formName: 'reportBy',
      viewComponent: () => (
        <Typography.Text>{data?.reportBy.fullName}</Typography.Text>
      ),
      editComponent: () => (
        <Typography.Text>{data?.reportBy.fullName}</Typography.Text>
      ),
    },
    {
      label: 'Địa chỉ',
      formName: 'address',
      full: true,
      viewComponent: () => <></>,
      editComponent: () => <Input placeholder="Địa chỉ sự cố" />,
    },
    {
      label: 'Vị trí sự cố',
      formName: 'issuePosition',
      viewComponent: () => <></>,
      editComponent: (form) => (
        <PositionInput
          disabled
          value={data?.issuePosition}
          onSubmit={(value) => {}}
        />
      ),
    },
    {
      label: 'Vị trí báo cáo',
      formName: 'createdPosition',
      viewComponent: () => <></>,
      editComponent: (form) => (
        <PositionInput
          disabled
          value={data?.createdPosition}
          onSubmit={(value) => {}}
        />
      ),
    },
    {
      label: 'Lý trình',
      formName: 'station',
      full: true,
      viewComponent: () => <></>,
      editComponent: (form) => (
        <SelectStation
          position={selectedPosition ?? data?.issuePosition}
          onSelectStation={(value) => {
            form?.setFieldValue('station', value);
          }}
        />
      ),
    },
    {
      label: 'Hình ảnh',
      formName: 'images',
      full: true,
      viewComponent: () => <></>,
      editComponent: () => (
        <UploadMultiple
          fileList={uploadedImage.map((image) => image.path)}
          type="image"
          onUpload={onUploadImageHandler}
          onRemove={onRemoveImageHandler}
        />
      ),
    },
    {
      label: 'Video',
      formName: 'videos',
      full: true,
      viewComponent: () => <></>,
      editComponent: () => (
        <UploadMultiple
          fileList={uploadedVideo.map((video) => video.path)}
          type="video"
          onUpload={onUploadVideoHandler}
          onRemove={onRemoveVideoHandler}
        />
      ),
    },
    {
      label: 'Ghi chú',
      formName: 'note',
      full: true,
      viewComponent: () => <></>,
      editComponent: () => <Input placeholder="Ghi chú" />,
    },
  ];

  const onUploadImageHandler = (newFile: UploadFileResult) => {
    const fileExsisted = uploadedImage.find(
      (file) => file.path === newFile.path
    );
    if (fileExsisted) return;
    setUploadedImage((prev) => [...prev, newFile]);
  };

  const onUploadVideoHandler = (newFile: UploadFileResult) => {
    const fileExsisted = uploadedVideo.find(
      (file) => file.path === newFile.path
    );
    if (fileExsisted) return;
    setUploadedVideo((prev) => [...prev, newFile]);
  };

  const onRemoveImageHandler = (filePath: string) => {
    const newFileList = uploadedImage.filter((file) => file.path !== filePath);
    setUploadedImage(newFileList);
  };

  const onRemoveVideoHandler = (filePath: string) => {
    const newFileList = uploadedVideo.filter((file) => file.path !== filePath);
    setUploadedVideo(newFileList);
  };

  const onCancelHandler = () => {
    onCancel();
  };

  const uploadImages = async (
    images: UploadFileResult[]
  ): Promise<UploadFile[]> => {
    const uploadPms: Promise<UploadFile>[] = [];
    images.forEach((image) => {
      try {
        const uploadPromise = uploadFile({
          file: image.file!,
          bucketName: 'issue-image',
        });
        uploadPms.push(uploadPromise);
      } catch (err) {}
    });
    const result = await Promise.all(uploadPms);
    return result;
  };

  const uploadVideos = async (
    videos: UploadFileResult[]
  ): Promise<UploadFile[]> => {
    const uploadPms: Promise<UploadFile>[] = [];
    videos.forEach((image) => {
      try {
        const uploadPromise = uploadFile({
          file: image.file!,
          bucketName: 'issue-video',
        });
        uploadPms.push(uploadPromise);
      } catch (err) {}
    });
    const result = await Promise.all(uploadPms);
    return result;
  };

  const removeFile = (files: string[]) => {
    files.forEach((file) => {
      deleteFile(file);
    });
  };

  const onSubmitHandler = async (value: IssueDataPayload) => {
    if (!id) return;

    const data: IssueDataPayload = { ...value };

    const newImages = uploadedImage.filter(
      (image) => !data?.images?.includes(image.path) ?? true
    );
    const deletedImages =
      data?.images?.filter(
        (image) => !uploadedImage.map((file) => file.path).includes(image)
      ) ?? [];

    const newVideos = uploadedVideo.filter(
      (video) => !data?.videos?.includes(video.path) ?? true
    );
    const deletedVideos =
      data?.videos?.filter(
        (video) => !uploadedVideo.map((file) => file.path).includes(video)
      ) ?? [];

    const uploadImgPms = uploadImages(newImages);
    const uploadVideoPms = uploadVideos(newVideos);

    const deleteImage = removeFile(deletedImages);
    const deleteVideo = removeFile(deletedVideos);

    const [images, videos] = await Promise.all([uploadImgPms, uploadVideoPms]);
    data.images = [
      ...(data.images?.filter((image) => !deletedImages.includes(image)) ?? []),
      ...images.map((image) => image.url),
    ];
    data.videos = [
      ...(data.videos?.filter((video) => !deletedVideos.includes(video)) ?? []),
      ...videos.map((video) => video.url),
    ];

    mutate(
      { id, data },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEY.GET_ISSUE_DETAIL, id]);
        },
      }
    );
  };

  const { mutate: commentIssue, isLoading: loadingComment } = useMutation({
    mutationFn: issueAPI.v2CommentIssue,
  });

  return (
    <Modal
      open
      title="Cập nhật sự cố"
      onCancel={onCancelHandler}
      footer={<></>}
      destroyOnClose
      width={'unset'}
      centered
    >
      {userProfile?.role.permissions?.some(
        (permission) => permission.id === PERMISSION.COMMENT_ISSUE
      ) && (
        <Form
          onFinish={(values) => {
            commentIssue({ id: id, data: values }, { onSuccess: () => {} });
          }}
        >
          <Descriptions bordered labelStyle={{ width: 250 }}>
            <Descriptions.Item label="Ý kiến">
              <Row gutter={[16, 16]} align="middle">
                <Col span={21}>
                  <Form.Item
                    name="note"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Vui lòng nhập ý kiến',
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    htmlType="submit"
                    type="primary"
                    danger
                    loading={loadingComment}
                  >
                    Ý kiến
                  </Button>
                </Col>
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </Form>
      )}

      <FormInput<IssueDataPayload>
        loading={isLoading && updateLoading}
        items={items}
        data={data ? formatData(data) : undefined}
        onCancel={onCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </Modal>
  );
};

export default EditIssueModal;
