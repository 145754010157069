import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Space, Spin, Typography } from 'antd';
import ContentWrapper from 'components/common/content-wrapper';
import Logo from 'components/common/logo';
import UserNotification from 'components/common/user-notification';
import MainMenu from 'components/menu/main-menu';
import UserMenu from 'components/menu/user-menu';
import theme from 'config/theme';
import { Suspense, memo, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { STORAGE_KEY } from 'utils/constants';

const AuthLayout = () => {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.key === STORAGE_KEY.ACCESS_TOKEN && !e.newValue) {
        localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
        localStorage.removeItem(STORAGE_KEY.FCM_TOKEN);
        navigate('/login', { replace: true });
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  if (!localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Sider
        trigger={null}
        collapsed={collapsed}
        collapsedWidth={0}
        style={{ overflow: 'auto' }}
      >
        <Logo />
        <MainMenu />
      </Layout.Sider>
      <Layout>
        <Layout.Header
          style={{
            height: 64,
            lineHeight: '64px',
            backgroundColor: theme.token?.colorBgContainer,
            padding: '0 24px',
            boxShadow: theme.token?.boxShadow,
          }}
        >
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ height: '100%' }}
          >
            <Col>
              <Space>
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{ width: 40, height: 40 }}
                />
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Ứng dụng quản lý tuần tra
                </Typography.Title>
              </Space>
            </Col>
            <Col>
              <Space>
                <UserNotification />
                <UserMenu />
              </Space>
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content style={{ padding: '24px', overflow: 'auto' }}>
          <ContentWrapper>
            <Suspense
              fallback={
                <Spin tip="Đang tải trang...">
                  <div style={{ height: 400 }} />
                </Spin>
              }
            >
              <Outlet />
            </Suspense>
          </ContentWrapper>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default memo(AuthLayout);
