import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import SelectPositionModal from 'components/modal/select-position-modal';
import { Position } from 'interfaces/position';
import { memo, useState } from 'react';

interface PositionInputProps {
  value?: Position;
  onSubmit: (value: Position) => void;
  disabled?: boolean;
  isMulti?: boolean;
  color?: string;
  onSubmitMulti?: (value: Array<Position>) => void;
  values?: Position[];
  showSubmit?: boolean;
}

const PositionInput = ({
  value,
  onSubmit,
  disabled,
  isMulti,
  color,
  onSubmitMulti,
  values,
  showSubmit,
}: PositionInputProps) => {
  const [showModal, setShowModal] = useState(false);

  const onSubmitHandler = (value: Position) => {
    onSubmit(value);
    setShowModal(false);
  };

  const onSubmitMultiHandler = (values: Position[]) => {
    onSubmitMulti?.(values);
    setShowModal(false);
  };

  return (
    <Space>
      <Button
        icon={<EnvironmentOutlined />}
        onClick={() => setShowModal(true)}
      />
      {value && (
        <Typography.Text>
          {value.address ?? `${value.lat}, ${value.lng}`}
        </Typography.Text>
      )}
      {values && <Typography.Text>{values.length} vị trí</Typography.Text>}
      {showModal && (
        <SelectPositionModal
          disabled
          value={value}
          onSubmit={onSubmitHandler}
          onCancel={() => setShowModal(false)}
          isMulti={isMulti}
          color={color}
          onSubmitMulti={onSubmitMultiHandler}
          values={values}
          showSubmit={showSubmit}
        />
      )}
    </Space>
  );
};

export default memo(PositionInput);
