import { notification } from 'antd';
import axios from 'axios';
import configuration from 'config/configuration';
import { STORAGE_KEY } from 'utils/constants';

let refreshingFunc: any = undefined;

const refreshTokenAPI = async (
  token: string
): Promise<{ accessToken: string }> => {
  return await axios
    .post(`${configuration.API_URL}/api/auth/refresh-token`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);
};

const axiosClient = axios.create({
  baseURL: `${configuration.API_URL}/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);

      if (accessToken) config.headers!.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      //TODO: handle refresh token
      const refreshToken = localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);
      if (refreshToken) {
        try {
          if (!refreshingFunc) {
            refreshingFunc = refreshTokenAPI(refreshToken);
          }

          const { accessToken } = await refreshingFunc;
          localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);

          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axiosClient.request(originalRequest);
        } catch (err) {
          localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
          localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
          localStorage.removeItem(STORAGE_KEY.FCM_TOKEN);
          window.location.href = '/login';
        } finally {
          refreshingFunc = undefined;
        }
      }
    }

    if (
      error?.response?.status === 403 &&
      (originalRequest.method === 'get' || originalRequest.method === 'GET')
    ) {
      window.location.href = '/no-permission';
    }

    notification.error({
      message:
        error.response?.data?.message ||
        error.response.statusText ||
        error.message,
    });

    return Promise.reject(error);
  }
);

export default axiosClient;
