import { DeleteOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { mapsAPI } from 'api/maps';
import { locationIcon } from 'assets/svg/location-icon';
import { DivIcon, LatLngLiteral as LatLngLiteralLeaflet } from 'leaflet';
import { useEffect, useState } from 'react';
import { Marker, Polyline, Popup, useMapEvents } from 'react-leaflet';

interface LatLngLiteral extends LatLngLiteralLeaflet {
  address?: string;
}

interface EventListenerProps {
  initPosition?: LatLngLiteral;
  onClick: (value: LatLngLiteral) => void;
  isMulti?: boolean;
  initPositions?: Array<LatLngLiteral>;
  onClickMulti?: (value: Array<LatLngLiteral>) => void;
  color?: string;
  showSubmit?: boolean;
}

const MapEventListener = ({
  initPosition,
  onClick,
  isMulti,
  initPositions,
  onClickMulti,
  color,
  showSubmit,
}: EventListenerProps) => {
  const [position, setPosition] = useState<LatLngLiteral | undefined>(
    initPosition
  );
  const [arrPosition, setArrPosition] = useState<Array<LatLngLiteral>>(
    initPositions ?? []
  );

  const map = useMapEvents({
    click(e) {
      if (isMulti) {
        setArrPosition([
          ...arrPosition,
          {
            lat: e.latlng.lat,
            lng: e.latlng.lng,
          },
        ]);

        mapsAPI
          .findAddress({
            lat: e.latlng.lat,
            lng: e.latlng.lng,
          })
          .then((data) =>
            setArrPosition((prev) =>
              prev.map((position) => {
                if (
                  position.lat === e.latlng.lat &&
                  position.lng === e.latlng.lng
                ) {
                  return {
                    ...position,
                    address: data.display_name,
                  };
                }

                return position;
              })
            )
          )
          .catch((error) => {
            console.log(error);
          });
      } else {
        setPosition(e.latlng);
        onClick(e.latlng);
      }

      map.flyTo(e.latlng, map.getZoom());
    },
  });

  useEffect(() => {
    if (onClickMulti) {
      onClickMulti(arrPosition);
    }
  }, [arrPosition]);

  if (isMulti) {
    return (
      <>
        {arrPosition.map((position, index) => (
          <Marker
            key={`position-${index}`}
            position={position}
            icon={
              new DivIcon({
                className: 'location-icon',
                html: locationIcon(color),
                iconSize: [40, 40],
              })
            }
          >
            <Popup className="">
              {showSubmit && (
                <div style={{ width: '100%' }}>
                  <Typography.Link
                    type="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      setArrPosition((prev) => {
                        const newArr = prev.filter(
                          (value) =>
                            value.lat !== position.lat &&
                            value.lng !== position.lng
                        );
                        onClickMulti?.(newArr);

                        return newArr;
                      });
                    }}
                  >
                    <DeleteOutlined />
                  </Typography.Link>
                </div>
              )}
              <Typography.Text>
                {position?.address ?? ` Vị trí ${index + 1}`}
              </Typography.Text>
            </Popup>
          </Marker>
        ))}
        {arrPosition.length > 0 && (
          <Polyline
            positions={arrPosition.map((position) => [
              position.lat,
              position.lng,
            ])}
            color={color}
          />
        )}
      </>
    );
  }

  return position ? (
    <Marker position={position!}>
      <Popup>Vị trí hiện tại</Popup>
    </Marker>
  ) : null;
};

export default MapEventListener;
