import { Spin } from 'antd';
import AuthLayout from 'layouts/auth';
import UnAuthLayout from 'layouts/un-auth';
import ErrorBoundary from 'pages/500';
import ConfirmNewUserPage from 'pages/confirm-new-user';
import LoginPage from 'pages/login';
import { lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { selectLoadingUser } from 'store/user/user.slice';
import { getMe } from 'store/user/user.thunk';
import { STORAGE_KEY } from 'utils/constants';
import './App.css';

// const DashboardPage = lazy(() => import('pages/dashboard'));
const ProfilePage = lazy(() => import('pages/profile'));
const UserPage = lazy(() => import('pages/user'));
const ProjectPage = lazy(() => import('pages/project'));
const OrgUnitPage = lazy(() => import('pages/org-unit'));
const MapIssuePage = lazy(() => import('pages/map-issue'));
const MapTrackingPage = lazy(() => import('pages/map-tracking'));
const MapProjectPage = lazy(() => import('pages/map-project'));
const RolePage = lazy(() => import('pages/role'));
const AreaPage = lazy(() => import('pages/area'));
const OptionTypePage = lazy(() => import('pages/option-type'));
const PermissionPage = lazy(() => import('pages/permission'));
const IssuePage = lazy(() => import('pages/issue'));
const PublicIssuePage = lazy(() => import('pages/public-issue'));
const NotFoundPage = lazy(() => import('pages/404'));
const NoPermissionPage = lazy(() => import('pages/403'));
const PostPage = lazy(() => import('pages/post'));
const ForgotPasswordPage = lazy(() => import('pages/forgot-password'));
const ResetPasswordPage = lazy(() => import('pages/reset-password'));
const ReportPage = lazy(() => import('pages/report'));
const PolicyPage = lazy(() => import('pages/policy'));

const App = () => {
  const dispatch = useAppDispatch();
  const loadingUser = useAppSelector(selectLoadingUser);

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)) {
      dispatch(getMe());
    }

    const listener = (e: StorageEvent) => {
      if (e.key === STORAGE_KEY.ACCESS_TOKEN && e.newValue) {
        dispatch(getMe());
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        {loadingUser ? (
          <Spin tip="Đang tải dữ liệu người dùng...">
            <div style={{ height: '100vh' }} />
          </Spin>
        ) : (
          <Routes>
            <Route element={<AuthLayout />}>
              {/* <Route path="/" element={<DashboardPage />} /> */}
              <Route index path="/issues" element={<IssuePage />} />
              <Route
                index
                path="/public-issues/*"
                element={<PublicIssuePage />}
              />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/users/*" element={<UserPage />} />
              <Route path="/roles" element={<RolePage />} />
              <Route path="/areas" element={<AreaPage />} />
              <Route path="/option-types" element={<OptionTypePage />} />
              <Route path="/permissions" element={<PermissionPage />} />
              <Route path="/map-tracking" element={<MapTrackingPage />} />
              <Route path="/map-issue" element={<MapIssuePage />} />
              <Route path="/projects/*" element={<ProjectPage />} />
              <Route path="/map-project" element={<MapProjectPage />} />
              <Route path="/posts/*" element={<PostPage />} />
              <Route path="/org-units/*" element={<OrgUnitPage />} />
              <Route path="/report" element={<ReportPage />} />
              <Route path="/no-permission" element={<NoPermissionPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route element={<UnAuthLayout />}>
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/forgot-password/*"
                element={<ForgotPasswordPage />}
              />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/confirm" element={<ConfirmNewUserPage />} />
            </Route>
            <Route path="/policy" element={<PolicyPage />} />
          </Routes>
        )}
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
