import { Select, SelectProps } from 'antd';
import { streetsAPI } from 'api/streets';
import { StreetCompact } from 'interfaces/street';
import debounce from 'lodash/debounce';
import { memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/query-key.enum';

interface Props extends SelectProps<number, StreetCompact> {
  districtId?: number;
}

const SelectStreet = ({ onSelect, districtId, ...props }: Props) => {
  const [filters, setFilters] = useState({
    keyword: '',
    districtId,
  });

  const onSearch = debounce(
    (value: string) => setFilters((prev) => ({ ...prev, keyword: value })),
    1000
  );

  const { isLoading, data } = useQuery(
    [QUERY_KEY.GET_STREET_COMPACT_LIST, filters],
    () => streetsAPI.findAllCompact(filters)
  );

  useEffect(() => {
    setFilters((prev) => ({ ...prev, districtId }));
  }, [districtId]);

  return (
    <Select
      {...props}
      showSearch
      onSearch={onSearch}
      filterOption={false}
      loading={isLoading}
      options={data?.rows}
      fieldNames={{ label: 'name', value: 'id' }}
    />
  );
};

export default memo(SelectStreet);
