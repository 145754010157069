import { PagingData } from "interfaces/common";
import { Station } from "interfaces/station";
import { FindAllStationPayload } from "types/stations";
import axiosClient from "utils/axios-client";

export const stationsAPI = {
  findAll: async (
    payload: FindAllStationPayload
  ): Promise<PagingData<Station>> => {
    return await axiosClient.get("stations", { params: payload });
  },
};
