import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../index";
import {
  checkCodeReset,
  confirmNewUser,
  forgotPassword,
  login,
  resetPassword,
} from "./auth.thunk";
import { AuthState } from "./auth.type";

const initialState: AuthState = {
  loadingSubmit: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          login.pending,
          forgotPassword.pending,
          resetPassword.pending,
          checkCodeReset.pending,
          confirmNewUser.pending
        ),
        (state) => {
          state.loadingSubmit = true;
        }
      )
      .addMatcher(
        isAnyOf(
          login.fulfilled,
          login.rejected,
          forgotPassword.fulfilled,
          forgotPassword.rejected,
          resetPassword.fulfilled,
          resetPassword.rejected,
          checkCodeReset.fulfilled,
          checkCodeReset.rejected,
          confirmNewUser.fulfilled,
          confirmNewUser.rejected
        ),
        (state) => {
          state.loadingSubmit = false;
        }
      );
  },
});

export const authActions = authSlice.actions;

export const selectAuth = (state: RootState) => state.authReducer;
export const selectLoadingAuth = (state: RootState) =>
  state.authReducer.loadingSubmit;

export default authSlice.reducer;
