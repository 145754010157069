export enum USER_STATUS {
  WAITING = 'WAITING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum POST_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum OPTION_TYPE {
  GENDER = 'GENDER',
  ORGANIZATION_UNIT_TYPE = 'ORGANIZATION_UNIT_TYPE',
  POST_TYPE = 'POST_TYPE',
  ISSUE_LEVEL = 'ISSUE_LEVEL',
  ISSUE_CATEGORY = 'ISSUE_CATEGORY',
}

export enum OPTION_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ISSUE_LOG_TYPE {
  COMMENT = 'COMMENT',
  HANDLED = 'HANDLED',
}

export enum NOTIFICATION_REF_TYPE {
  ISSUE = 'ISSUE',
}
