import { ConfigProvider, Layout, Spin } from 'antd';
import logoFull from 'assets/images/logo-full.png';
import unauthLayoutBackground from 'assets/images/unauth-layout-background.jpg';
import { ReactNode, Suspense, memo } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectUserProfile } from 'store/user/user.slice';

type Props = {
  children?: ReactNode;
};

const UnAuthLayout = ({ children }: Props) => {
  const { pathname } = useLocation();
  const profile = useAppSelector(selectUserProfile);
  if (profile?.id) {
    return <Navigate to="/" replace />;
  }

  return (
    <ConfigProvider componentSize="large">
      <Layout style={{ minHeight: '100vh' }}>
        <div style={{ display: 'flex' }}>
          <img
            src={unauthLayoutBackground}
            alt="background"
            style={{
              minHeight: '100vh',
              maxWidth: '60%',
              width: 'calc(100vw - 576px)',
              objectFit: 'cover',
            }}
          />
          <Layout.Content style={{ backgroundColor: 'white' }}>
            <div style={{ display: 'flex', marginTop: 24 }}>
              <img
                src={logoFull}
                alt="logo"
                style={{ maxWidth: 400, margin: 'auto' }}
              />
            </div>
            <div style={{ padding: 64 }}>
              <Suspense
                fallback={
                  <Spin tip="Đang tải trang...">
                    <div style={{ height: 400 }} />
                  </Spin>
                }
              >
                {children || <Outlet />}
                {pathname !== '/login' && (
                  <div style={{ marginTop: 24, textAlign: 'center' }}>
                    <Link to="/login" style={{ color: 'black' }}>
                      <u>
                        <strong>Đăng nhập</strong>
                      </u>
                    </Link>
                  </div>
                )}
              </Suspense>
            </div>
          </Layout.Content>
        </div>
      </Layout>
    </ConfigProvider>
  );
};

export default memo(UnAuthLayout);
