import { Select, SelectProps } from 'antd';
import { optionsAPI } from 'api/options';
import { Option } from 'interfaces/option';
import { memo } from 'react';
import { useQuery } from 'react-query';
import { OPTION_TYPE } from 'utils/enum';
import { QUERY_KEY } from 'utils/query-key.enum';

interface Props extends SelectProps<number, Option> {}

const SelectIssueLevelType = (props: Props) => {
  const { isLoading, data } = useQuery(QUERY_KEY.GET_ISSUE_LEVEL_OPTION, () =>
    optionsAPI.findAll({
      optionTypeId: OPTION_TYPE.ISSUE_LEVEL,
      isLoadAll: true,
    })
  );

  return (
    <Select
      {...props}
      loading={isLoading}
      options={data?.rows ?? []}
      fieldNames={{ value: 'id', label: 'name' }}
      popupMatchSelectWidth={false}
    />
  );
};

export default memo(SelectIssueLevelType);
