import { Button, Form, Input, Space, Typography, notification } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectLoadingAuth } from "store/auth/auth.slice";
import { confirmNewUser } from "store/auth/auth.thunk";

const ConfirmNewUserPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectLoadingAuth);

  const onFinishHandler = (values: {
    password: string;
    confirmPassword: string;
  }) => {
    const token = searchParams.get("token");
    if (!token) {
      notification.error({ message: "Token không hợp lệ" });
      return;
    }
    dispatch(
      confirmNewUser({
        token,
        password: values.password,
        callback: () => {
          navigate("/login");
        },
      })
    );
  };

  return (
    <Space direction="vertical" align="center">
      <Typography.Text>
        Bạn đã được mời sử dụng phần mềm{" "}
        <Typography.Text strong>Giám sát giao thông</Typography.Text>.
      </Typography.Text>
      <Typography.Paragraph>
        Vui lòng tạo mật khẩu để bắt đầu sử dụng.
      </Typography.Paragraph>
      <Form
        style={{ width: "50vw", maxWidth: 500, minWidth: 300 }}
        onFinish={onFinishHandler}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: `Mật khẩu không thể bỏ trống`,
            },
            {
              min: 6,
              message: `Mật khẩu phải gồm ít nhất 6 kí tự`,
            },
          ]}
        >
          <Input.Password placeholder="Mật khẩu mới" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: `Mật khẩu xác nhận không thể bỏ trống`,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Hai mật khẩu đã nhập không khớp!")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Xác nhận mật khẩu" />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ width: "100%", marginTop: 12 }}
          >
            Xác nhận
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default ConfirmNewUserPage;
