import { PagingData } from "interfaces/common";
import { Option } from "interfaces/option";
import {
  CreateOptionPayload,
  DeleteOptionPayload,
  FindAllOptionPayload,
  FindOneOptionPayload,
  UpdateOptionPayload,
} from "types/option";
import axiosClient from "utils/axios-client";

export const optionsAPI = {
  findAll: async (
    payload: FindAllOptionPayload
  ): Promise<PagingData<Option>> => {
    return await axiosClient.get("options", { params: payload });
  },

  findOne: async (payload: FindOneOptionPayload): Promise<Option> => {
    return await axiosClient.get(`options/${payload.id}`);
  },
  create: async (payload: CreateOptionPayload): Promise<Option> => {
    return await axiosClient.post("options", payload.data);
  },
  update: async (payload: UpdateOptionPayload): Promise<Option> => {
    return await axiosClient.put(`options/${payload.id}`, payload.data);
  },
  delete: async (payload: DeleteOptionPayload): Promise<Option> => {
    return await axiosClient.delete(`options/${payload.id}`);
  },
};
