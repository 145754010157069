import { Avatar, Button, Col, List, Row, Space, Typography } from 'antd';
import { notificationsAPI } from 'api/notifications';
import EditIssueModal from 'components/issue/edit-issue-modal';
import { Notification } from 'interfaces/notification';
import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FindAllPayload } from 'types/common';
import { DEFAULT_PAGINATION } from 'utils/constants';
import { NOTIFICATION_REF_TYPE } from 'utils/enum';
import { QUERY_KEY } from 'utils/query-key.enum';

interface LoadMoreProps {
  show: boolean;
  onLoadMore: () => void;
}

const LoadMoreButton = ({ show, onLoadMore }: LoadMoreProps) => {
  return show ? (
    <div style={{ textAlign: 'center' }}>
      <Button type="text" onClick={onLoadMore}>
        Xem thêm
      </Button>
    </div>
  ) : null;
};

interface Props {
  onClickItem: (id: number) => void;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NotificationList = ({
  onClickItem,
  setShowNotification,
}: Props) => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<FindAllPayload>({
    pageIndex: DEFAULT_PAGINATION.page,
    pageSize: DEFAULT_PAGINATION.pageSize,
  });

  const [notification, setNotification] = useState<Notification[]>([]);
  const [selectedIssue, setSelectedIssue] = useState<string>();

  const { data, isLoading } = useQuery(
    [QUERY_KEY.GET_NOTIFICATION_LIST, pagination],
    () => notificationsAPI.findAll(pagination),
    {
      onSuccess(data) {
        if (data.rows.length <= 0) return;
        setNotification((prev) => [...prev, ...data.rows]);
      },
    }
  );

  const onLoadMoreHandler = () => {
    setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex! + 1 }));
  };

  return (
    <>
      <List
        loading={isLoading}
        dataSource={notification}
        loadMore={
          <LoadMoreButton
            show={!!data && data.count !== notification.length}
            onLoadMore={onLoadMoreHandler}
          />
        }
        renderItem={(notification) => (
          <List.Item>
            <List.Item.Meta
              title={
                <>
                  <Typography.Link
                    style={{ opacity: notification.isReaded ? 0.7 : 1 }}
                    onClick={() => {
                      if (!notification.isReaded) {
                        notification.isReaded = true;
                        return onClickItem(notification.id);
                      }

                      if (notification.refType && notification.refId) {
                        switch (notification.refType) {
                          case NOTIFICATION_REF_TYPE.ISSUE:
                            setShowNotification(false);
                            setSelectedIssue(notification.refId);
                            break;
                          default:
                            break;
                        }
                      }
                    }}
                  >
                    {notification.title}
                  </Typography.Link>
                  {!notification.isReaded && (
                    <Typography.Text
                      style={{ color: '#007fff', float: 'right' }}
                    >
                      •
                    </Typography.Text>
                  )}
                </>
              }
              description={
                <>
                  <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
                    {notification.content}
                  </Typography.Paragraph>
                  <Row justify="space-between" gutter={4}>
                    <Col>
                      <Space>
                        <Avatar
                          size={20}
                          src={notification.createdByUser.avatar}
                        />
                        <Typography.Paragraph style={{ margin: 0 }}>
                          {notification.createdByUser.fullName}
                        </Typography.Paragraph>
                      </Space>
                    </Col>
                    <Col>
                      <Typography.Paragraph
                        type="secondary"
                        style={{ margin: 0, fontSize: 12 }}
                      >
                        {moment(notification.createdAt).format(
                          'HH:mm DD/MM/YYYY'
                        )}
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </>
              }
            />
          </List.Item>
        )}
      />
      {selectedIssue && (
        <EditIssueModal
          id={selectedIssue}
          onCancel={() => setSelectedIssue(undefined)}
          onSubmit={() => setSelectedIssue(undefined)}
        />
      )}
    </>
  );
};
