import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row, Space, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { memo } from 'react';
import { UploadFileResult } from 'types/upload-file';
import { getBase64 } from 'utils/upload-image';

interface Props {
  fileList: string[];
  width?: number;
  height?: number;
  onUpload: (value: UploadFileResult) => void;
  type: 'image' | 'video';
  onRemove?: (value: string) => void;
}

const UploadMultiple = ({
  fileList,
  width,
  height,
  onUpload,
  type,
  onRemove,
}: Props) => {
  const beforeUploadHandler = (file: RcFile) => {
    getBase64(file, (url) => {
      onUpload({ path: url, file });
    });

    return false;
  };

  const onRemoveHandler = (filePath: string) => {
    if (onRemove) onRemove(filePath);
  };

  return (
    <Row wrap>
      {fileList.map((file) => (
        <Col key={file} style={{ padding: 4 }}>
          <Space style={{ position: 'relative' }}>
            {type === 'video' ? (
              <video width="100%">
                <source src={file} type="video/mp4" />
              </video>
            ) : (
              <Image
                width={width ?? 100}
                height={height ?? 100}
                src={file}
                style={{
                  borderRadius: '10%',
                  cursor: 'pointer',
                  objectFit: 'cover',
                }}
                preview={false}
              />
            )}
          </Space>
          <Button
            type="ghost"
            shape="circle"
            icon={<CloseCircleOutlined />}
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => onRemoveHandler(file)}
          />
        </Col>
      ))}
      <Col>
        <Upload
          showUploadList={false}
          beforeUpload={beforeUploadHandler}
          accept={type === 'video' ? 'video/*' : 'image/*'}
        >
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            style={{
              width: width ?? 100,
              height: height ?? 100,
              borderRadius: '10%',
            }}
          />
        </Upload>
      </Col>
    </Row>
  );
};

export default memo(UploadMultiple);
