export enum QUERY_KEY {
  GET_USER_LIST = 'GET_USER_LIST',
  GET_USER_DETAIL = 'GET_USER_DETAIL',
  GET_ROLE_LIST = 'GET_ROLE_LIST',
  GET_ROLE_COMPACT_LIST = 'GET_ROLE_COMPACT_LIST',
  GET_ROLE_DETAIL = 'GET_ROLE_DETAIL',
  GET_ORG_UNIT_LIST = 'GET_ORG_UNIT_LIST',
  GET_ORG_UNIT_COMPACT_LIST = 'GET_ORG_UNIT_COMPACT_LIST',
  GET_ORG_UNIT_DETAIL = 'GET_ORG_UNIT_DETAIL',
  GET_STREET_LIST = 'GET_STREET_LIST',
  GET_STREET_COMPACT_LIST = 'GET_STREET_COMPACT_LIST',
  GET_STREET_DETAIL = 'GET_STREET_DETAIL',
  GET_POST_LIST = 'GET_POST_LIST',
  GET_POST_DETAIL = 'GET_POST_DETAIL',
  GET_ISSUE_LIST = 'GET_ISSUE_LIST',
  GET_ISSUE_DETAIL = 'GET_ISSUE_DETAIL',
  GET_ISSUE_LOG = 'GET_ISSUE_LOG',
  GET_PROJECT_LIST = 'GET_PROJECT_LIST',
  GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL',
  GET_PERMISSION_LIST = 'GET_PERMISSION_LIST',
  GET_DISTRICT_LIST = 'GET_DISTRICT_LIST',
  GET_DISTRICT_COMPACT_LIST = 'GET_DISTRICT_COMPACT_LIST',
  GET_DISTRICT_DETAIL = 'GET_DISTRICT_DETAIL',
  GET_GENDER_OPTION = 'GET_GENDER_OPTION',
  GET_ORG_UNIT_TYPE_OPTION = 'GET_ORG_UNIT_TYPE_OPTION',
  GET_POST_TYPE_OPTION = 'GET_POST_TYPE_OPTION',
  GET_ISSUE_LEVEL_OPTION = 'GET_ISSUE_LEVEL_OPTION',
  GET_ISSUE_CATEGORY_OPTION = 'GET_ISSUE_CATEGORY_OPTION',
  FIND_MAP_ADDRESS = 'FIND_MAP_ADDRESS',
  SEARCH_MAP_ADDRESS = 'SEARCH_MAP_ADDRESS',
  GET_STATION_LIST = 'GET_STATION_LIST',
  GET_LOCATION_TRACKING_LIST = 'GET_LOCATION_TRACKING_LIST',
  GET_LOCATION_TRACKING_DETAIL = 'GET_LOCATION_TRACKING_DETAIL',
  GET_ISSUE_STATE_LIST = 'GET_ISSUE_STATE_LIST',
  GET_PUBLIC_ISSUE_LIST = 'GET_PUBLIC_ISSUE_LIST',
  GET_PUBLIC_ISSUE_DETAIL = 'GET_PUBLIC_ISSUE_DETAIL',
  GET_OPTION_TYPE_LIST = 'GET_OPTION_TYPE_LIST',
  GET_OPTION_LIST = 'GET_OPTION_LIST',
  GET_OPTION_DETAIL = 'GET_OPTION_DETAIL',
  GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST',
  GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT',
  GET_SEGMENT_LIST = 'GET_SEGMENT_LIST',
  GET_SEGMENT_DETAIL = 'GET_SEGMENT_DETAIL',
  GET_ISSUE_COMMENT_LOG = 'GET_ISSUE_COMMENT_LOG',
  GET_ISSUE_HANDLE_LOG = 'GET_ISSUE_HANDLE_LOG',
  GET_USER_COMPACT_LIST = 'GET_USER_COMPACT_LIST',
}
