import { PagingData } from 'interfaces/common';
import { Issue, IssueLog, IssueState } from 'interfaces/issue';
import { FindAllPayload } from 'types/common';
import {
  ChangeStateIssuePayload,
  CompleteIssuePayload,
  FindAllIssuePayload,
  FindOneIssuePayload,
  GetLogPayLoad,
  UpdateIssuePayload,
} from 'types/issue';
import axiosClient from 'utils/axios-client';

export const issueAPI = {
  findAll: async (payload: FindAllIssuePayload): Promise<PagingData<Issue>> => {
    return await axiosClient.get('issues', { params: payload });
  },
  findOne: async (payload: FindOneIssuePayload): Promise<Issue> => {
    return await axiosClient.get(`issues/${payload.id}`);
  },
  changeState: async (payload: ChangeStateIssuePayload): Promise<Issue> => {
    return await axiosClient.put(`issues/state/${payload.id}`, payload.data);
  },
  complete: async (payload: CompleteIssuePayload): Promise<Issue> => {
    return await axiosClient.put(
      `issues/completed/${payload.id}`,
      payload.data
    );
  },
  getLogs: async (payload: GetLogPayLoad): Promise<PagingData<IssueLog>> => {
    return await axiosClient.get(`issues/log/${payload.id}`, {
      params: payload.paging,
    });
  },
  update: async (payload: UpdateIssuePayload): Promise<Issue> => {
    return await axiosClient.put(`issues/${payload.id}`, payload.data);
  },
  findAllState: async (
    payload: FindAllPayload
  ): Promise<PagingData<IssueState>> => {
    return await axiosClient.get('issues/state', { params: payload });
  },
  exportExcel: async (payload: FindAllIssuePayload): Promise<Buffer> => {
    return await axiosClient.post('issues/export-excel', payload, {
      responseType: 'blob',
    });
  },
  exportExcelReport: async (payload: FindAllIssuePayload): Promise<Buffer> => {
    return await axiosClient.post('issues/export-excel-report', payload, {
      responseType: 'blob',
    });
  },
  v2FindAllCommentLog: async (
    payload: GetLogPayLoad
  ): Promise<PagingData<IssueLog>> => {
    return await axiosClient.get(`issues/v2/comment-log/${payload.id}`, {
      params: payload.paging,
    });
  },
  v2FindAllHandleLog: async (
    payload: GetLogPayLoad
  ): Promise<PagingData<IssueLog>> => {
    return await axiosClient.get(`issues/v2/handle-log/${payload.id}`, {
      params: payload.paging,
    });
  },
  v2CommentIssue: async (payload: ChangeStateIssuePayload) => {
    return await axiosClient.post(
      `issues/v2/comment/${payload.id}`,
      payload.data
    );
  },
  v2HandleIssue: async (payload: ChangeStateIssuePayload) => {
    return await axiosClient.post(
      `issues/v2/handle/${payload.id}`,
      payload.data
    );
  },

  excelMauhinh: async (payload: FindAllIssuePayload): Promise<Buffer> => {
    return await axiosClient.post('issues/excels/mauhinh', payload, {
      responseType: 'blob',
    });
  },
  excelNhatkytuanduong: async (
    payload: FindAllIssuePayload
  ): Promise<Buffer> => {
    return await axiosClient.post('issues/excels/nhatkytuanduong', payload, {
      responseType: 'blob',
    });
  },
  excelBaocaotonghop: async (payload: FindAllIssuePayload): Promise<Buffer> => {
    return await axiosClient.post('issues/excels/baocaotonghop', payload, {
      responseType: 'blob',
    });
  },
  excelMaulenhDBGT: async (payload: FindAllIssuePayload): Promise<Buffer> => {
    return await axiosClient.post('issues/excels/maulenhDBGT', payload, {
      responseType: 'blob',
    });
  },
};
