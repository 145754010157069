import { Select, SelectProps } from 'antd';
import { districtsAPI } from 'api/districts';
import { DistrictCompact } from 'interfaces/district';
import debounce from 'lodash/debounce';
import { memo, useState } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/query-key.enum';

interface Props extends SelectProps<number, DistrictCompact> {}

const SelectDistrict = ({ onSelect, ...props }: Props) => {
  const [filters, setFilters] = useState({
    keyword: '',
  });

  const onSearch = debounce(
    (value: string) => setFilters((prev) => ({ ...prev, keyword: value })),
    1000
  );

  const { isLoading, data } = useQuery(
    [QUERY_KEY.GET_DISTRICT_COMPACT_LIST, filters],
    () => districtsAPI.findAllCompact(filters)
  );

  return (
    <Select
      {...props}
      showSearch
      onSearch={onSearch}
      filterOption={false}
      loading={isLoading}
      options={data?.rows}
      fieldNames={{ label: 'name', value: 'id' }}
    />
  );
};

export default memo(SelectDistrict);
