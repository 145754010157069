export enum PERMISSION {
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  READ_USER = 'READ_USER',
  CREATE_ISSUE = 'CREATE_ISSUE',
  UPDATE_ISSUE = 'UPDATE_ISSUE',
  READ_ISSUE = 'READ_ISSUE',
  HANDLE_ISSUE = 'HANDLE_ISSUE',
  COMPLETE_ISSUE = 'COMPLETE_ISSUE',
  CREATE_POST = 'CREATE_POST',
  UPDATE_POST = 'UPDATE_POST',
  READ_POST = 'READ_POST',
  CREATE_ROLE = 'CREATE_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  READ_ROLE = 'READ_ROLE',
  CREATE_ORGANIZATION_UNIT = 'CREATE_ORGANIZATION_UNIT',
  UPDATE_ORGANIZATION_UNIT = 'UPDATE_ORGANIZATION_UNIT',
  READ_ORGANIZATION_UNIT = 'READ_ORGANIZATION_UNIT',
  CREATE_PROJECT = 'CREATE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  READ_PROJECT = 'READ_PROJECT',
  READ_LOCATION_TRACKING = 'READ_LOCATION_TRACKING',
  UPDATE_PERMISSION = 'UPDATE_PERMISSION',
  UPDATE_PUBLIC_ISSUE = 'UPDATE_PUBLIC_ISSUE',
  READ_PUBLIC_ISSUE = 'READ_PUBLIC_ISSUE',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  READ_CATEGORY = 'READ_CATEGORY',
  CREATE_ADDRESS = 'CREATE_ADDRESS',
  UPDATE_ADDRESS = 'UPDATE_ADDRESS',
  DELETE_ADDRESS = 'DELETE_ADDRESS',
  READ_ADDRESS = 'READ_ADDRESS',
  COMMENT_ISSUE = 'COMMENT_ISSUE',
}
