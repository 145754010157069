import { Dropdown, MenuProps, Space, Typography } from 'antd';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { logout } from 'store/auth/auth.thunk';
import { selectUserProfile } from 'store/user/user.slice';
import UserAvatar from '../common/user-avatar';

const UserMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectUserProfile);

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: 'Hồ sơ cá nhân',
      onClick: () => navigate('/profile'),
    },
    {
      type: 'divider',
    },
    {
      key: 'sign-out',
      label: 'Đăng xuất',
      danger: true,
      onClick: () =>
        dispatch(
          logout({
            callback: () => {
              navigate('/login');
            },
          })
        ),
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Space style={{ cursor: 'pointer' }}>
        <UserAvatar src={profile?.avatar} size={'small'} />
        <Typography.Text strong>{profile?.fullName}</Typography.Text>
      </Space>
    </Dropdown>
  );
};

export default memo(UserMenu);
