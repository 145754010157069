import { Button, Form, Input, Typography } from 'antd';
import { notificationsAPI } from 'api/notifications';
import { getFirebaseToken } from 'config/firebase';
import { memo } from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { selectAuth } from 'store/auth/auth.slice';
import { login } from 'store/auth/auth.thunk';
import { LoginPayload } from 'store/auth/auth.type';
import { DEVICE_TYPE, STORAGE_KEY } from 'utils/constants';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);

  const initialValues: LoginPayload = {
    username: localStorage.getItem(STORAGE_KEY.USERNAME) || '',
    password: '',
  };

  const { mutate: acceptPushNotification } = useMutation({
    mutationFn: notificationsAPI.acceptPushNotification,
  });

  const onGetFirebaseTokenHandler = (firebaseToken: string) => {
    acceptPushNotification({
      deviceType: DEVICE_TYPE,
      notificationToken: firebaseToken,
    });
  };

  const onFinish = (values: LoginPayload) => {
    dispatch(
      login({
        ...values,
        callback: async () => {
          await getFirebaseToken(onGetFirebaseTokenHandler);
          navigate('/issues', { replace: true });
        },
      })
    );
  };

  return (
    <>
      <Typography.Title
        level={2}
        style={{ marginBottom: 24, textAlign: 'center' }}
      >
        Đăng nhập
      </Typography.Title>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: `Email không thể bỏ trống`,
            },
            {
              type: 'email',
              message: `Email không đúng định dạng`,
            },
            {
              max: 255,
              message: 'Email không thể dài hơn 255 kí tự',
            },
          ]}
        >
          <Input size="large" placeholder="Email đăng ký" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: `Mật khẩu không thể bỏ trống`,
            },
          ]}
        >
          <Input.Password size="large" placeholder={'Mật khẩu'} />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            type="primary"
            htmlType="submit"
            loading={auth.loadingSubmit}
            style={{ width: '100%', marginTop: 12 }}
          >
            Đăng nhập
          </Button>
        </Form.Item>
        <div style={{ marginTop: 24, textAlign: 'center' }}>
          <Link to="/forgot-password" style={{ color: 'black' }}>
            <u>
              <strong>Quên mật khẩu?</strong>
            </u>
          </Link>
        </div>
      </Form>
    </>
  );
};

export default memo(LoginPage);
