import { Button, Descriptions, Form, FormInstance, Space } from 'antd';
import { useEffect } from 'react';
import { FormItem } from 'types/form';

interface Props<T> {
  items: FormItem[];
  data?: T;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  loading?: boolean;
  onValuesChange?: (
    form: FormInstance<T>,
    changedValue: object,
    values: T
  ) => void;
}

const FormInput = <T extends object>({
  items,
  data,
  onSubmit,
  onCancel,
  loading,
  onValuesChange,
}: Props<T>) => {
  const [form] = Form.useForm();
  const formValue = Form.useWatch([], form);

  const onSubmitHandler = (values: any) => {
    onSubmit(values);
  };

  const onCancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmitHandler}
      onValuesChange={(changedValue, values) =>
        onValuesChange ? onValuesChange(form, changedValue, values) : undefined
      }
    >
      <Descriptions bordered column={2} labelStyle={{ width: 250 }}>
        {items.map(
          (item) =>
            (!item.checkCondition ||
              item?.checkCondition(formValue) === true) && (
              <Descriptions.Item
                key={item.formName}
                label={item.label}
                span={item.full ? 2 : 1}
                contentStyle={{
                  maxWidth: item.full
                    ? 'calc(100% - 250px)'
                    : 'calc(50% - 250px)',
                }}
              >
                <Form.Item
                  name={item.formName}
                  rules={item.rules}
                  valuePropName={item.valuePropName}
                  required={item.required}
                  style={{ margin: 0 }}
                >
                  {item.editComponent(form)}
                </Form.Item>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
      <Space style={{ width: '100%', justifyContent: 'end', marginTop: 24 }}>
        <Button onClick={onCancelHandler}>Huỷ</Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Xác nhận
        </Button>
      </Space>
    </Form>
  );
};

export default FormInput;
