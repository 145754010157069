import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'interfaces/user';
import { RootState } from 'store';
import {
  createUser,
  deleteUser,
  getMe,
  getUserDetail,
  getUserList,
  updateProfile,
  updateUser,
} from './user.thunk';
import { UserState } from './user.type';

const initialState: UserState = {
  profile: {
    loading: false,
    data: undefined,
  },
  list: {
    loading: false,
    data: {
      count: 0,
      rows: [],
    },
  },
  user: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<User>) => {
      state.profile.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state) => {
        state.profile.loading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.profile.loading = false;
        state.profile.data = action.payload;
      })
      .addCase(getMe.rejected, (state) => {
        state.profile.loading = false;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.list.loading = false;
        state.list.data = action.payload;
      })
      .addCase(getUserDetail.fulfilled, (state, action) => {
        state.list.loading = false;
        state.user = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.list.loading = false;
        state.user = action.payload;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.list.loading = false;
        state.user = action.payload;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.list.loading = false;
        state.profile.data = action.payload;
      })
      .addMatcher(
        isAnyOf(
          getUserList.rejected,
          getUserDetail.rejected,
          createUser.rejected,
          updateUser.rejected,
          deleteUser.rejected,
          deleteUser.fulfilled,
          updateProfile.rejected
        ),
        (state) => {
          state.list.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          getUserList.pending,
          getUserDetail.pending,
          createUser.pending,
          updateUser.pending,
          deleteUser.pending,
          updateProfile.pending
        ),
        (state) => {
          state.list.loading = true;
        }
      );
  },
});

export const { setUserProfile } = userSlice.actions;

export const selectLoadingUser = (state: RootState) =>
  state.userReducer.profile.loading;
export const selectUserProfile = (state: RootState) =>
  state.userReducer.profile.data;
export const selectLoadingUserList = (state: RootState) =>
  state.userReducer.list.loading;
export const selectUserList = (state: RootState) => state.userReducer.list.data;
export const selectUserDetail = (state: RootState) => state.userReducer.user;

export default userSlice.reducer;
