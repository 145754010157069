import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateUserPayload,
  DeleteUserPayload,
  FindOneUserPayload,
  FindAllUserPayload,
  UpdateProfilePayload,
  UpdateUserPayload,
} from './user.type';
import { userAPI } from 'api/users';

export const getMe = createAsyncThunk('user/getMe', async () => {
  return await userAPI.getMe();
});

export const updateProfile = createAsyncThunk(
  'user/updateProfile',
  async (payload: UpdateProfilePayload) => {
    const result = await userAPI.updateProfile(payload);
    if (payload.callback) payload.callback(result);
    return result;
  }
);

export const getUserList = createAsyncThunk(
  'user',
  async (payload: FindAllUserPayload) => {
    return await userAPI.findAll(payload);
  }
);

export const getUserDetail = createAsyncThunk(
  'user/detail',
  async (payload: FindOneUserPayload) => {
    const user = await userAPI.findOne(payload);
    if (user && payload.callback) payload.callback(user);
    return user;
  }
);

export const createUser = createAsyncThunk(
  'user/create',
  async (payload: CreateUserPayload) => {
    const result = await userAPI.createUser(payload);
    if (payload.callback) payload.callback(result);
    return result;
  }
);

export const updateUser = createAsyncThunk(
  'user/update',
  async (payload: UpdateUserPayload) => {
    const result = await userAPI.updateUser(payload);
    if (payload.callback) payload.callback(result);
    return result;
  }
);

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (payload: DeleteUserPayload) => {
    const result = await userAPI.deleteUser(payload);
    if (payload.callback) payload.callback();
    return result;
  }
);
