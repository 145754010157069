import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#007fff',
    colorText: '#141414',
    colorTextSecondary: '#707070',
    colorBgContainer: '#ffffff',
    boxShadow: '0 0 4px 4px rgba(0,0,0,.03)',
  },
};

export default theme;
