import { Menu } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectUserProfile } from 'store/user/user.slice';
import { PERMISSION } from 'utils/permission.enum';

const MainMenu = () => {
  let location = useLocation();
  const [current, setCurrent] = useState('');
  const navigate = useNavigate();
  const profile = useAppSelector(selectUserProfile);

  const [menuItemList, setMenuItemList] = useState<ItemType<MenuItemType>[]>(
    []
  );

  const itemList = [
    // {
    //   key: 'dashboard',
    //   label: <Link to="/">Tổng quan</Link>,
    // },
    {
      key: 'issues',
      label: <Link to="/issues">Báo cáo</Link>,
      permission: PERMISSION.READ_ISSUE,
    },
    {
      key: 'report',
      label: <Link to="/report">Báo cáo thống kê</Link>,
      permission: PERMISSION.READ_ISSUE,
    },
    {
      key: 'public-issues',
      label: <Link to="/public-issues">Báo cáo người dân</Link>,
      permission: PERMISSION.READ_PUBLIC_ISSUE,
    },
    {
      key: 'posts',
      label: <Link to="/posts">Bài viết</Link>,
      permission: PERMISSION.READ_POST,
    },
    {
      key: 'projects',
      label: <Link to="/projects">Duy tu</Link>,
      permission: PERMISSION.READ_PROJECT,
    },
    {
      key: 'map-tracking',
      label: <Link to="/map-tracking">Hành trình</Link>,
      permission: PERMISSION.READ_LOCATION_TRACKING,
    },
    // {
    //   key: 'map-issue',
    //   label: <Link to="/map-issue">Truy vết tuần tra</Link>,
    //   permission: PERMISSION.READ_ISSUE,
    // },
    {
      key: 'map-project',
      label: <Link to="/map-project">Bản đồ </Link>,
      permission: PERMISSION.READ_PROJECT,
    },
    {
      key: 'org-units',
      label: <Link to="/org-units">Đơn vị tổ chức</Link>,
      permission: PERMISSION.READ_ORGANIZATION_UNIT,
    },
    {
      key: 'areas',
      label: <Link to="/areas">Quận - Đường - Đoạn</Link>,
      permission: PERMISSION.READ_ADDRESS,
    },
    {
      key: 'option-types',
      label: <Link to="/option-types">Danh mục</Link>,
      permission: PERMISSION.READ_CATEGORY,
    },
    {
      key: 'users',
      label: <Link to="/users">Người dùng</Link>,
      permission: PERMISSION.READ_USER,
    },
    {
      key: 'roles',
      label: <Link to="/roles">Vai trò</Link>,
      permission: PERMISSION.READ_ROLE,
    },
    // {
    //   key: 'permissions',
    //   label: <Link to="/permissions">Phân quyền</Link>,
    // },
  ];

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    const selectedItem = menuItemList.find((item) => path === item?.key);
    if (selectedItem) {
      setCurrent(selectedItem.key?.toString() ?? '');
      return;
    }
    if (location.pathname === '/') {
      setCurrent('issues');
      navigate('/issues');
      return;
    }
    setCurrent('');
  }, [location, menuItemList]);

  useEffect(() => {
    if (profile) {
      setMenuItemList(
        itemList.filter(
          (item) =>
            !item.permission ||
            profile.role?.permissions?.some(
              (permission) => permission.id === item.permission
            )
        )
      );
    }
  }, [profile]);

  return (
    <Menu
      theme={'dark'}
      mode="inline"
      defaultSelectedKeys={['1']}
      items={menuItemList}
      selectedKeys={[current]}
    />
  );
};

export default memo(MainMenu);
