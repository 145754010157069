import { createAsyncThunk } from '@reduxjs/toolkit';

import { notification } from 'antd';

import { authAPI } from 'api/auth';
import { setUserProfile } from 'store/user/user.slice';
import { STORAGE_KEY } from 'utils/constants';
import {
  CheckCodeResetPayload,
  ConfirmNewUserPayload,
  ForgotPasswordPayload,
  LoginPayload,
  LogoutPayload,
  ResetPasswordPayload,
} from './auth.type';

export const login = createAsyncThunk(
  'auth/login',
  async (payload: LoginPayload, { dispatch }) => {
    const data = await authAPI.login(payload);

    dispatch(setUserProfile(data.profile));
    localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, data.accessToken);
    localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, data.refreshToken);
    if (payload.callback) payload.callback();
    return;
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (payload?: LogoutPayload) => {
    try {
      await authAPI.logout();
    } catch (err) {}

    localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
    localStorage.removeItem(STORAGE_KEY.FCM_TOKEN);

    if (payload && payload.callback) payload.callback();
    return;
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (payload: ForgotPasswordPayload) => {
    await authAPI.forgotPassword(payload);

    if (payload.callback) payload.callback();
    return;
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload: ResetPasswordPayload) => {
    await authAPI.resetPassword(payload);
    notification.success({
      message: 'Đổi mật khẩu thành công',
    });

    if (payload.callback) payload.callback();
    return;
  }
);

export const checkCodeReset = createAsyncThunk(
  'auth/checkCodeReset',
  async (payload: CheckCodeResetPayload) => {
    const result = await authAPI.checkCodeReset(payload);

    if (payload.callback) payload.callback(result);
    return result;
  }
);

export const confirmNewUser = createAsyncThunk(
  'auth/confirmNewUser',
  async (payload: ConfirmNewUserPayload) => {
    await authAPI.confirmNewUser(payload);

    if (payload.callback) payload.callback();
    return;
  }
);
