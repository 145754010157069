import { PagingData } from 'interfaces/common';
import { Notification } from 'interfaces/notification';
import {
  AcceptPushNotificationPayload,
  FindAllNotificationPayload,
  UpdateNotificationPayload,
} from 'types/notification';
import axiosClient from 'utils/axios-client';

export const notificationsAPI = {
  findAll: async (
    payload: FindAllNotificationPayload
  ): Promise<PagingData<Notification>> => {
    return await axiosClient.get('notifications', { params: payload });
  },
  update: async (payload: UpdateNotificationPayload): Promise<Notification> => {
    return await axiosClient.put(`notifications/read/${payload.id}`);
  },
  count: async (): Promise<PagingData<Notification>> => {
    return await axiosClient.get('notifications/count');
  },
  acceptPushNotification: async (payload: AcceptPushNotificationPayload) => {
    return await axiosClient.post(
      'notifications/accept-push-notification',
      payload
    );
  },
};
