import configuration from 'config/configuration';
import {
  CheckCodeResetData,
  CheckCodeResetPayload,
  ConfirmNewUserPayload,
  ForgotPasswordPayload,
  LoginData,
  LoginPayload,
  ResetPasswordPayload,
} from 'store/auth/auth.type';
import axiosClient from 'utils/axios-client';
import { DEVICE_TYPE } from 'utils/constants';

export const authAPI = {
  async login(payload: LoginPayload): Promise<LoginData> {
    return await axiosClient.post('auth/login', payload);
  },

  async logout() {
    return await axiosClient.post('auth/logout', { deviceType: DEVICE_TYPE });
  },

  async forgotPassword(payload: ForgotPasswordPayload) {
    return await axiosClient.post('auth/forgot-password', {
      email: payload.email,
      url: `${window.location.host}/reset-password`,
    });
  },

  async resetPassword(payload: ResetPasswordPayload) {
    return await axiosClient.post(
      `${configuration.API_URL}/api/auth/reset-password`,
      { password: payload.password },
      { headers: { Authorization: `Bearer ${payload.token}` } }
    );
  },

  async checkCodeReset(
    payload: CheckCodeResetPayload
  ): Promise<CheckCodeResetData> {
    return await axiosClient.post(
      `${configuration.API_URL}/api/auth/check-code-reset`,
      payload
    );
  },

  async confirmNewUser(payload: ConfirmNewUserPayload) {
    return await axiosClient.post(
      `${configuration.API_URL}/api/auth/confirm`,
      { password: payload.password },
      { headers: { Authorization: `Bearer ${payload.token}` } }
    );
  },
};
