import { PagingData } from "interfaces/common";
import { MapAddress } from "interfaces/map";
import { FindAddressPayload, SearchAddressPayload } from "types/map";
import axiosClient from "utils/axios-client";

export const mapsAPI = {
  findAddress: async (payload: FindAddressPayload): Promise<MapAddress> => {
    return axiosClient.get("maps/find-address", { params: payload });
  },
  searchAddress: async (
    payload: SearchAddressPayload
  ): Promise<PagingData<MapAddress>> => {
    return axiosClient.get("maps/search-address", { params: payload });
  },
};
