import { notification } from 'antd';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  MessagePayload,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { STORAGE_KEY } from 'utils/constants';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDmb8umDiRNf32z8WkW2q-dT7VcLfRtAJo',
  authDomain: 'giamsatgiaothong.firebaseapp.com',
  projectId: 'giamsatgiaothong',
  storageBucket: 'giamsatgiaothong.appspot.com',
  messagingSenderId: '882852218166',
  appId: '1:882852218166:web:7a4b09febe532cbd335f97',
  measurementId: 'G-YCH0H5JYGY',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);

export const getFirebaseToken = async (onGetTokenSuccess: any) => {
  try {
    const isSupportedFirebase = await isSupported();
    if (!isSupportedFirebase) return;
    const messaging = getMessaging(firebaseApp);

    return getToken(messaging, {
      vapidKey:
        'BEO0DtbSVK5T0RHAMtFqgyXJg3yx3TZ9FDZH6CafP__BFrUElu_4oYZW2f03IqD78i6HC9ICN_SvYWCG6b-zY_0',
    })
      .then((currentToken) => {
        if (currentToken) {
          onGetTokenSuccess(currentToken);
          localStorage.setItem(STORAGE_KEY.FCM_TOKEN, currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          // shows on the UI that permission is required
          notification.info({
            message: 'Xin hãy cho phép trang web gửi thông báo',
          });
          Notification.requestPermission();
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
        notification.info({
          message: 'Xin hãy cho phép trang web gửi thông báo',
        });
        Notification.requestPermission();
      });
  } catch (err) {
    console.log(err);
  }
};

export const onMessageListener = async () => {
  try {
    const isSupportedFirebase = await isSupported();

    if (!isSupportedFirebase) return;

    const messaging = getMessaging(firebaseApp);

    return onMessage(messaging, (payload) => {
      console.log(payload);
    });
  } catch (err) {
    console.log(err);
  }
};

export const onReceiveMessage = async (
  onMessageHandler: (payload: MessagePayload) => void
) => {
  try {
    const isSupportedFirebase = await isSupported();

    if (!isSupportedFirebase) return;

    const messaging = getMessaging(firebaseApp);

    return onMessage(messaging, (payload) => {
      onMessageHandler(payload);
    });
  } catch (err) {
    console.log(err);
  }
};
