import { ConfigProvider } from 'antd';
import viVN from 'antd/locale/vi_VN';
import theme from 'config/theme';
import 'dayjs/locale/vi';
import ReactDOM from 'react-dom/client';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { Provider } from 'react-redux';
import { store } from 'store';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.log(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      console.log(error);
    },
  }),
});

root.render(
  <Provider store={store}>
    <ConfigProvider theme={theme} locale={viVN}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
