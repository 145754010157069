import { UploadFile } from "interfaces/upload-file";
import { UploadFilePayload } from "types/upload-file";
import axiosClient from "utils/axios-client";

export const filesAPI = {
  upload: async ({
    file,
    bucketName,
    oldPath,
  }: UploadFilePayload): Promise<UploadFile> => {
    const formData = new FormData();
    formData.append("file", file);
    return await axiosClient.post("files", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      params: {
        bucketName,
        oldPath,
      },
    });
  },
  delete: async (filePath: string) => {
    return await axiosClient.delete(`files/${filePath}`);
  },
};
