import { PagingData } from 'interfaces/common';
import { District, DistrictCompact } from 'interfaces/district';
import { FindAllPayload } from 'types/common';
import {
  CreateDistrictPayload,
  DeleteDistrictPayload,
  FindOneDistrictPayload,
  UpdateDistrictPayload,
} from 'types/district';
import axiosClient from 'utils/axios-client';

export const districtsAPI = {
  findAll: async (payload: FindAllPayload): Promise<PagingData<District>> => {
    return await axiosClient.get('districts', { params: payload });
  },
  findAllCompact: async (
    payload: FindAllPayload
  ): Promise<PagingData<DistrictCompact>> => {
    return await axiosClient.get('districts/compact', { params: payload });
  },
  findOne: async (payload: FindOneDistrictPayload): Promise<District> => {
    return await axiosClient.get(`districts/${payload.id}`);
  },
  create: async (payload: CreateDistrictPayload): Promise<District> => {
    return await axiosClient.post('districts', payload.data);
  },
  update: async (payload: UpdateDistrictPayload): Promise<District> => {
    return await axiosClient.put(`districts/${payload.id}`, payload.data);
  },
  delete: async (payload: DeleteDistrictPayload): Promise<District> => {
    return await axiosClient.delete(`districts/${payload.id}`);
  },
};
