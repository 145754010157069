import { Select, SelectProps } from 'antd';
import { stationsAPI } from 'api/stations';
import { Position } from 'interfaces/position';
import { Station } from 'interfaces/station';
import { useQuery } from 'react-query';
import { QUERY_KEY } from 'utils/query-key.enum';

interface Props extends SelectProps {
  value?: Station;
  position?: Position;
  onSelectStation: (value?: Station) => void;
  componentId?: any;
  allowClear?: boolean;
}

const SelectStation = ({
  position,
  value,
  onSelectStation,
  componentId,
  allowClear,
}: Props) => {
  const { isLoading, data } = useQuery(
    [`${QUERY_KEY.GET_STATION_LIST}-${componentId ?? ''}`, position],
    () => stationsAPI.findAll({ lat: position!.lat, lng: position!.lng }),
    { enabled: !!position }
  );

  const onSelectStationHandler = (value: string) => {
    const result = data?.rows.find((station) => station.id === value);
    if (result) {
      onSelectStation(result);
    } else {
      onSelectStation();
    }
  };

  return (
    <Select
      loading={isLoading}
      value={value?.id}
      options={data?.rows ?? []}
      fieldNames={{ label: 'title', value: 'id' }}
      onChange={onSelectStationHandler}
      allowClear={allowClear}
    />
  );
};

export default SelectStation;
