import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, Popover, Typography, notification } from 'antd';
import { notificationsAPI } from 'api/notifications';
import { NotificationList } from 'components/list/notification-list';
import { onReceiveMessage } from 'config/firebase';
import { memo, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAppSelector } from 'store';
import { selectUserProfile } from 'store/user/user.slice';
import { FindAllNotificationPayload } from 'types/notification';
import { DEFAULT_PAGINATION } from 'utils/constants';
import { QUERY_KEY } from 'utils/query-key.enum';

import { MessagePayload } from 'firebase/messaging';

const UserNotification = () => {
  const userProfile = useAppSelector(selectUserProfile);
  const [filters, setFilters] = useState<FindAllNotificationPayload>({
    pageIndex: DEFAULT_PAGINATION.page,
    pageSize: DEFAULT_PAGINATION.pageSize,
  });
  const [showNotification, setShowNotification] = useState(false);

  const { data: notifyCount, refetch: refetchNotifyCount } = useQuery(
    [QUERY_KEY.GET_NOTIFICATION_COUNT],
    () => notificationsAPI.count(),
    { enabled: !!userProfile }
  );

  const { mutate } = useMutation({
    mutationFn: notificationsAPI.update,
    onSuccess: () => {
      refetchNotifyCount();
    },
  });

  const onClickHandler = (id: number) => {
    mutate({ id });
  };

  useEffect(() => {
    try {
      if (!userProfile) return;

      onReceiveMessage((payload: MessagePayload) => {
        notification.info({
          message: payload.notification?.title,
          description: payload.notification?.body,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }, [userProfile]);

  return (
    <Popover
      title={
        <Typography.Title level={3} style={{ marginTop: 0 }}>
          Thông báo
        </Typography.Title>
      }
      content={
        <NotificationList
          setShowNotification={setShowNotification}
          onClickItem={onClickHandler}
        />
      }
      overlayStyle={{ width: 380, maxHeight: '80vh', overflow: 'auto' }}
      trigger="click"
      open={showNotification}
      onOpenChange={setShowNotification}
      placement="bottom"
      style={{ width: 360 }}
    >
      <Badge count={notifyCount?.count ?? 0}>
        <Button shape="circle" type="text" icon={<BellOutlined />} />
      </Badge>
    </Popover>
  );
};

export default memo(UserNotification);
