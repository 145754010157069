import { PagingData } from 'interfaces/common';
import { User, UserCompact } from 'interfaces/user';
import {
  CreateUserPayload,
  DeleteUserPayload,
  FindAllUserPayload,
  FindOneUserPayload,
  UpdateProfilePayload,
  UpdateUserPayload,
} from 'store/user/user.type';
import { ReInviteUserPayload } from 'types/user';
import axiosClient from 'utils/axios-client';
import { USER_STATUS } from 'utils/enum';

export const userAPI = {
  async getMe(): Promise<User> {
    return await axiosClient.get('users/profile');
  },

  async updateProfile(payload: UpdateProfilePayload): Promise<User> {
    return await axiosClient.put('users/profile', payload.data);
  },

  async findAll(payload: FindAllUserPayload): Promise<PagingData<User>> {
    return await axiosClient.get('users', { params: payload });
  },

  async findOne(payload: FindOneUserPayload): Promise<User> {
    return await axiosClient.get(`users/${payload.id}`);
  },

  async createUser(payload: CreateUserPayload): Promise<User> {
    return await axiosClient.post('users', {
      ...payload.data,
      url: `${window.location.origin}/confirm?token=`,
    });
  },

  async updateUser(payload: UpdateUserPayload): Promise<User> {
    return await axiosClient.put(`users/${payload.id}`, payload.data);
  },

  async deleteUser(payload: DeleteUserPayload): Promise<User> {
    return await axiosClient.put(`users/${payload.id}`, {
      status: USER_STATUS.INACTIVE,
    });
  },

  async reInviteUser({ id, ...payload }: ReInviteUserPayload): Promise<any> {
    payload.url = `${window.location.origin}/confirm?token=`;
    return await axiosClient.post(`users/re-invite/${id}`, payload);
  },

  async findAllCompact(
    payload: FindAllUserPayload
  ): Promise<PagingData<UserCompact>> {
    return await axiosClient.get('users/compact', { params: payload });
  },
};
