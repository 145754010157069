import { combineReducers } from 'redux';
import authReducer from './auth/auth.slice';
import { logout } from './auth/auth.thunk';
import userReducer from './user/user.slice';

const appReducer = combineReducers({
  authReducer,
  userReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === logout.pending.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
