import { PagingData } from 'interfaces/common';
import { Street, StreetCompact } from 'interfaces/street';
import {
  CreateStreetPayload,
  DeleteStreetPayload,
  FindAllStreetPayload,
  FindOneStreetPayload,
  UpdateStreetPayload,
} from 'types/street';
import axiosClient from 'utils/axios-client';

export const streetsAPI = {
  findAll: async (
    payload: FindAllStreetPayload
  ): Promise<PagingData<Street>> => {
    return await axiosClient.get('streets', { params: payload });
  },
  findAllCompact: async (
    payload: FindAllStreetPayload
  ): Promise<PagingData<StreetCompact>> => {
    return await axiosClient.get('streets/compact', { params: payload });
  },
  findOne: async (payload: FindOneStreetPayload): Promise<Street> => {
    return await axiosClient.get(`streets/${payload.id}`);
  },
  create: async (payload: CreateStreetPayload): Promise<Street> => {
    return await axiosClient.post('streets', payload.data);
  },
  update: async (payload: UpdateStreetPayload): Promise<Street> => {
    return await axiosClient.put(`streets/${payload.id}`, payload.data);
  },
  delete: async (payload: DeleteStreetPayload): Promise<Street> => {
    return await axiosClient.delete(`streets/${payload.id}`);
  },
};
