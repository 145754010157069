import { SelectProps, Select } from "antd";
import { mapsAPI } from "api/maps";
import useDebounce from "hook/use-debounce";
import { MapAddress } from "interfaces/map";
import { Position } from "interfaces/position";
import { useState } from "react";
import { useQuery } from "react-query";
import { QUERY_KEY } from "utils/query-key.enum";

interface Props extends SelectProps<string, MapAddress> {
  onSelectAddress: (value: Position) => void;
}

const SelectAddress = ({ onSelectAddress, ...props }: Props) => {
  const [textSearch, setTextSearch] = useState<string>("");
  const debounceTextSearch = useDebounce(textSearch, 500);
  const { isLoading, data } = useQuery(
    [QUERY_KEY.SEARCH_MAP_ADDRESS, debounceTextSearch],
    () => mapsAPI.searchAddress({ keyword: debounceTextSearch })
  );

  const onSelectHandler = (value: string) => {
    const result = data?.rows.find((address) => address.display_name === value);
    if (result && onSelectAddress)
      onSelectAddress({
        lat: result.lat,
        lng: result.lon,
        address: result.display_name,
      });
  };

  return (
    <Select
      {...props}
      onSearch={setTextSearch}
      filterOption={false}
      loading={isLoading && props.loading}
      options={data?.rows}
      fieldNames={{ label: "display_name", value: "display_name" }}
      onSelect={onSelectHandler}
    />
  );
};

export default SelectAddress;
